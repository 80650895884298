import Rails from '@rails/ujs';
require("trix")

//= require jquery
//= require jquery_ujs
//= require turbolinks
//= require_tree .
//= require draggable
//= require editor


require("@rails/actiontext")
require("channels");
console.log('ssss');
Rails.start();
require("@rails/activestorage").start();

window.Rails = Rails;

console.log('232312312');